var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{staticClass:"py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-btn',{attrs:{"color":_vm.$store.state.secondaryColor,"outlined":"","small":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"caption"},[_vm._v("back")])],1)],1),_c('v-spacer')],1),_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[(!_vm.currentBusiness)?_c('v-flex',{staticClass:"py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-select',{staticClass:"caption",attrs:{"dense":"","solo":"","label":_vm.$t('select business'),"item-text":"businessName","item-value":"id","items":_vm.ownersBusiness},on:{"change":_vm.onSelectShop},model:{value:(_vm.selectedShop),callback:function ($$v) {_vm.selectedShop=$$v},expression:"selectedShop"}})],1):_vm._e(),_c('v-flex',{staticClass:"text-right py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","placeholder":_vm.$t('search shifts')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-flex',{staticClass:"py-2 text-right",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-btn',{attrs:{"color":_vm.$store.state.primaryColor,"outlined":"","small":""},on:{"click":_vm.newShift}},[_vm._v(" New shift ")])],1)],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.shifts,"items-per-page":20,"search":_vm.search},scopedSlots:_vm._u([{key:"item.businessId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBusinessName(item.businessId))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","left":"","color":_vm.$store.state.primaryColor},on:{"click":function($event){return _vm.updateShift(item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":"","left":"","color":_vm.$store.state.secondaryColor},on:{"click":function($event){return _vm.viewShift(item.id)}}},[_vm._v("visibility")]),_c('v-icon',{attrs:{"small":"","left":"","color":"red"},on:{"click":function($event){return _vm.deleteDialog(item.id, item.shiftName)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.delDialog),callback:function ($$v) {_vm.delDialog=$$v},expression:"delDialog"}},[_c('v-card',[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"my-5",attrs:{"outlined":"","large":"","fab":"","color":"red darken-4"}},[_c('v-icon',[_vm._v("warning")])],1)],1),_c('v-card-text',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t("are you sure"))+"?")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("do you really want to delete the"))+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.shiftName))]),_vm._v(_vm._s(_vm.$t("shift"))+" ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":_vm.$store.state.secondaryColor,"text":"","small":""},on:{"click":function($event){_vm.delDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":_vm.$store.state.primaryColor,"text":"","loading":_vm.loading,"small":""},on:{"click":_vm.deleteShift}},[_vm._v(" "+_vm._s(_vm.$t("confirm"))+" ")])],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","center":"","color":"success lighten-5 success--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"success darken-3"},on:{"click":function($event){_vm.snackSuccess = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackSuccess),callback:function ($$v) {_vm.snackSuccess=$$v},expression:"snackSuccess"}},[_c('span',[_vm._v(_vm._s(_vm.$t("shift deleted successfully"))+"!")])]),_c('v-snackbar',{attrs:{"top":"","center":"","color":"red lighten-5 error--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"error darken-3"},on:{"click":function($event){_vm.snackError = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackError),callback:function ($$v) {_vm.snackError=$$v},expression:"snackError"}},[_c('span',[_vm._v(_vm._s(_vm.$t("could not delete this shift. Try again later")))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }