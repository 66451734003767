<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between class="mb-2">
        <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
          <v-btn
            :color="$store.state.secondaryColor"
            outlined
            small
            @click="goBack"
          >
            <v-icon small left>mdi-arrow-left</v-icon>
            <span class="caption">back</span>
          </v-btn>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
      <v-layout row wrap justify-space-between class="mb-2">
        <v-flex xs12 sm4 md4 lg4 v-if="!currentBusiness" class="py-1 px-1">
          <v-select
            dense
            solo
            class="caption"
            :label="$t('select business')"
            item-text="businessName"
            item-value="id"
            :items="ownersBusiness"
            v-model="selectedShop"
            @change="onSelectShop"
          ></v-select>
        </v-flex>
        <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
          <v-text-field
            v-model="search"
            dense
            solo
            :placeholder="$t('search shifts')"
          ></v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm4 md4 lg4 class="py-2 text-right">
          <v-btn
            :color="$store.state.primaryColor"
            outlined
            small
            @click="newShift"
          >
            New shift
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 md12 lg12>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="shifts"
            :items-per-page="20"
            :search="search"
          >
            <template v-slot:[`item.businessId`]="{ item }">
              {{ getBusinessName(item.businessId) }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                left
                :color="$store.state.primaryColor"
                @click="updateShift(item.id)"
                >mdi-pencil</v-icon
              >
              <v-icon
                small
                left
                :color="$store.state.secondaryColor"
                @click="viewShift(item.id)"
                >visibility</v-icon
              ><v-icon
                small
                left
                color="red"
                @click="deleteDialog(item.id, item.shiftName)"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-dialog v-model="delDialog" persistent max-width="290">
        <v-card>
          <div class="text-center">
            <v-btn class="my-5" outlined large fab color="red darken-4">
              <v-icon>warning</v-icon>
            </v-btn>
          </div>

          <v-card-text class="text-center">
            <p>{{ $t("are you sure") }}?</p>
            <p>
              {{ $t("do you really want to delete the") }}
              <span class="font-weight-bold">{{ shiftName }}</span
              >{{ $t("shift") }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :color="$store.state.secondaryColor"
              text
              small
              @click="delDialog = false"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :color="$store.state.primaryColor"
              text
              :loading="loading"
              small
              @click="deleteShift"
            >
              {{ $t("confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackSuccess"
        top
        center
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("shift deleted successfully") }}!</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccess = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="snackError"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not delete this shift. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackError = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import db from "@/plugins/fb";

export default {
  data: () => ({
    delDialog: false,
    shiftId: "",
    shiftName: "",
    snackSuccess: false,
    snackError: false,
    loading: true,
    search: "",

    selectedShop: [],
    shifts: [],
  }),
  computed: {
    currentBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    ownersBusiness() {
      return this.$store.getters.getBusinesses;
    },
    headers() {
      return [
        {
          text: this.$t("shift name"),
          value: "shiftName",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("business"),
          value: "businessId",
          class: "blue-grey darken-3 white--text font-weight-regular",
          align: "center",
        },
        {
          text: this.$t("start time"),
          value: "startTime",
          class: "blue-grey darken-3 white--text font-weight-regular",
          align: "center",
        },
        {
          text: this.$t("end time"),
          value: "endTime",
          class: "blue-grey darken-3 white--text font-weight-regular",
          align: "center",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
      ];
    },
  },

  created() {
    this.getShifts();
  },

  methods: {
    getShifts() {
      this.loading = true;
      this.shifts = [];
      if (this.currentBusiness) {
        db.collection("shifts")
          .where("businessId", "==", this.currentBusiness)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.shifts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          });

        this.loading = false;
      } else {
        db.collection("shifts")
          .where("businessId", "in", this.ownersBusinessIds)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.shifts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          });

        this.loading = false;
      }
    },

    onSelectShop() {
      this.loading = true;
      this.shifts = [];
      db.collection("shifts")
        .where("businessId", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.shifts.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.loading = false;
        });
    },

    deleteDialog(shiftId, shiftName) {
      this.shiftId = shiftId;
      this.shiftName = shiftName;
      this.delDialog = true;
    },

    deleteShift() {
      this.loading = true;
      db.collection("shifts")
        .doc(this.shiftId)
        .delete()
        .then(() => {
          this.loading = false;
          this.delDialog = false;
          this.snackSuccess = true;
          this.getShifts();
        })
        .catch(() => {
          this.loading = false;
          this.delDialog = false;
          this.snackError = true;
        });
    },

    getBusinessName(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );
      return bizId.businessName;
    },
    updateShift(itemId) {
      this.$router.push({ name: "UpdateShift", params: { id: itemId } });
    },

    viewShift(itemId) {
      this.$router.push({ name: "ViewShift", params: { id: itemId } });
    },

    newShift() {
      this.$router.push({ name: "CreateShift" });
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
